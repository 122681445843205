<template>
    <div class="accountWrap">
        <Header></Header>
        <topBg>
            <div slot="text" class="textWrap" v-html="$t('oa.accountTitle1')"></div>
        </topBg>
        <div class="accountContent content">
            <div class="topBgView">
                <!-- <div class="bgContent">
                    <div class="rightRouter">
                        <div class="childrenRoute">
                            <router-link class="routeItem" :to="item.path" tag="div" v-for="(item,index) in childrenRoute" :class="{active:$route.meta.activeChildren == item.activeChildren}" :key="index">{{item.text}}</router-link>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="userAvatar">
                <div>
                    <img src="../../assets/images/Avatar.png" />
                </div>
            </div>
            <router-view></router-view>
        </div>

    </div>
</template>
<script>
import url from "@/api/url.js";
import Header from "@/components/header.vue";
import topBg from "@/components/topBg.vue";
export default {
    components: {
        Header,
        topBg,
    },
    data() {
        return {
            childrenRoute: [
                {
                    text: "基本信息",
                    path: "/account/basicInformation",
                    activeChildren: "5-1",
                },
                // {
                //     text: "就业信息",
                //     path: "/account/employmentInformation",
                //     activeChildren: "5-2",
                // },
                // {
                //     text: "财务状况",
                //     path: "/account/financialStatus",
                //     activeChildren: "5-3",
                // },
            ],
        };
    },
    created() {
        this.initLang();
    },
    watch: {
        "$i18n.locale": function (newVal) {
            this.initLang();
        },
    },
    methods: {
        initLang() {
            this.childrenRoute[0].text = this.$t("oa.baseInfor");
            this.childrenRoute[1].text = this.$t("oa.Employment");
            this.childrenRoute[2].text = this.$t("oa.Finance");
        },
        submitForm(formName) {
            if (this.loading) return;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$axios
                        .post(url.resstPassword, {
                            ...this.ruleForm,
                        })
                        .then((res) => {
                            this.$message({
                                message: this.$t("oa.steSuccess"),
                                type: "success",
                            });
                            localStorage.removeItem("AUTtoken");
                            this.$router.replace({ name: "Login" });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.accountWrap {
    /deep/.el-input.is-disabled .el-input__inner {
        color: #333;
        height: 60px;
        border-color: #bfc5d1;
        background-color: #fff;
    }
    /deep/.el-select {
        width: 100%;
    }
    .topBgView {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        .bgContent {
            width: 545px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            .rightRouter {
                display: flex;
                justify-content: space-between;
                position: relative;
            }
        }
    }
    .userAvatar {
        width: 545px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .reset {
            display: flex;
            align-items: center;
            margin-top: 10px;
            .el-button {
                background-color: #305A9E;
                border-color: #305A9E;
                border-radius: 30px;
            }
            .el-button.is-round {
                padding: 10px 23px;
            }
        }
    }
    .accountContent {
        flex: 1;
        border-top: 1px solid #e9e9e9;
        .childrenRoute {
            width: 545px;
            display: flex;
            border-bottom: 3px solid rgba(0, 0, 0, 0.2);
            .routeItem {
                line-height: 50px;
                font-size: 15px;
                padding: 0 20px;
                flex: 1;
                cursor: pointer;
                position: relative;
                color: #000;
                transform: translateY(3px);
                text-align: center;
            }
            .routeItem.active {
                font-size: 14px;
                border-color: #305A9E;
                font-weight: bold;
                border-bottom: 5px solid #305A9E;
            }
        }
    }
    /deep/.el-form-item {
        margin-bottom: 10px;
    }
}
</style>